import { Grid, Text } from '@etchteam/mobius';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { useUser } from '@/lib/hooks';

import styles from './Footer.module.scss';

const FooterLinks = () => {
  const { t } = useTranslation();
  const [user] = useUser();

  const links = [
    {
      url: 'https://wrap.ngo/privacy-policy',
      text: t('components.canvas.footer.links.privacy'),
    },
    {
      url: 'https://wrap.ngo/cookies-policy',
      text: t('components.canvas.footer.links.cookies'),
    },
    {
      url: 'https://wrap.ngo/terms-conditions',
      text: t('components.canvas.footer.links.terms'),
    },
    {
      url: 'https://wrap.ngo/contact-us',
      text: t('components.canvas.footer.links.contact'),
    },
  ];

  return (
    <Text size="sm">
      <Grid wrap>
        <Grid.Item>© WRAP 2021–2024</Grid.Item>
        {links.map((link) => (
          <Grid.Item key={link.url}>
            <Link
              href={link.url}
              className={styles.footer__link}
              target="_blank"
              rel="noreferrer"
            >
              {link.text}
            </Link>
          </Grid.Item>
        ))}
        {user?.isAdmin && (
          <Grid.Item>
            <Link href="/documentation" className={styles.footer__link}>
              Developer Documentation
            </Link>
          </Grid.Item>
        )}
      </Grid>
    </Text>
  );
};

export default FooterLinks;
