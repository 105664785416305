import { NextRouter } from 'next/router';

import { User, UserAccess, UserRole, UserTool } from '@/types/user.type';

/**
 * Filter the user access list based on the tool and users role
 */
export function getUserAccessForTool(userAccess: UserAccess[], tool: UserTool) {
  return (userAccess ?? []).filter((access) => {
    const hasToolAccess = access.tool === tool;
    const hasEditorAccess =
      access.role === UserRole.EDITOR || access.role === UserRole.ADMIN;

    return hasToolAccess && hasEditorAccess;
  });
}

/**
 * Verify the user has permissions to access the tool for the current LA slug
 */
export default function hasToolAccess(
  user: User,
  router: NextRouter,
  tool: UserTool,
): boolean {
  const slug = router.query?.slug as string;
  const isAdmin = user?.isAdmin;
  const userSlugs = getUserAccessForTool(user?.access, tool).map(
    (access) => access.localAuthority.slug,
  );

  return !slug || isAdmin || userSlugs.includes(slug);
}
