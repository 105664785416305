import { NextRouter } from 'next/router';

import hasToolAccess from '@/integrations/wrap-api/lib/hasToolAccess';
import { User, UserTool } from '@/types/user.type';

const paths = {
  auth: /^(\/|\/auth\/.+)$/,
  admin: /^\/documentation$/,
  manageData: /^\/manage-data\/.+$/,
  eteep: /^\/eteep\/.+$/,
};

/**
 * Verify the user has permissions to access to the current route.
 *
 * @returns `true` if the user has permissions to access the route
 */
export default function hasRouteAccess(
  user: User,
  router: NextRouter,
): boolean {
  const path = router.pathname;
  const isLoggedOut = !user?.id;
  const isAuthPath = paths.auth.exec(path) !== null;

  if (isLoggedOut) {
    // Logged out users can only access auth paths
    return isAuthPath;
  } else {
    if (isAuthPath) {
      // Logged in users shouldn't need to be on auth paths
      return false;
    }

    if (paths.admin.exec(path) && !user?.isAdmin) {
      // Admin paths are only for admin users
      return false;
    }

    if (
      paths.manageData.exec(path) &&
      !hasToolAccess(user, router, UserTool.MANAGE_DATA)
    ) {
      // Access needs to be granted to manage data for this LA
      return false;
    }

    if (
      paths.eteep.exec(path) &&
      !hasToolAccess(user, router, UserTool.ETEEP)
    ) {
      // Access need to be granted to access the ETEEP tool for this LA
      return false;
    }

    return true;
  }
}
