import { LocalAuthority } from './local-authority.type';
import { Preference } from './preference.type';

export interface User {
  id: number;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  email: string;
  preferences: Preference[];
  blockedAt?: string;
  createdAt: string;
  updatedAt: string;
  access: UserAccess[];
}

export interface UserAccess {
  id: number;
  user: User;
  localAuthority: LocalAuthority;
  role: UserRole;
  tool: UserTool;
  grantedBy: User;
  createdAt: string;
  updatedAt: string;
}

export interface UserPreference {
  id?: number;
  key: string;
  value: unknown;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
}

export enum UserTool {
  MANAGE_DATA = 'MANAGE_DATA',
  ETEEP = 'ETEEP',
  MANAGE_USERS = 'MANAGE_USERS',
}
