import { Card, Grid, Label, Enter } from '@etchteam/mobius';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import tools from './tools';

export default function ToolGrid() {
  const { t } = useTranslation();

  return (
    <Grid wrap>
      {tools.map((tool, i) => (
        <Grid.Item xs={12} md={6} flex key={tool.url}>
          <Enter type="fade-in-up" flex fullWidth delay={`${i * 0.2}s`}>
            <Card as={Link} href={tool.url} border disabled={tool.disabled}>
              <Card.Header>
                <Grid spacing="sm" align="center">
                  <Grid.Item>
                    <h3>
                      <strong>{tool.title}</strong>
                    </h3>
                  </Grid.Item>
                  {!!tool.disabled && (
                    <Grid.Item>
                      <Label>{t('dashboard.tools.general.user.pending')}</Label>
                    </Grid.Item>
                  )}
                </Grid>
              </Card.Header>
              <Card.Body>
                <Grid justify="center" wrap>
                  <Grid.Item xs={12} xl={6}>
                    <p>{tool.description}</p>
                  </Grid.Item>
                  <Grid.Item xs="auto" xl={6} shrink>
                    <Image src={tool.img} alt="" width={424} height={212} />
                  </Grid.Item>
                </Grid>
              </Card.Body>
            </Card>
          </Enter>
        </Grid.Item>
      ))}
    </Grid>
  );
}
