import 'lib/polyfills';
import { Grid, Icon } from '@etchteam/mobius';
import NextApp from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { withUrqlClient, NextUrqlAppContext } from 'next-urql';
import { parse } from 'next-useragent';
import { useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { cacheExchange, fetchExchange } from 'urql';

import Footer from '@/components/canvas/Footer/Footer';
import TitleBar from '@/components/canvas/TitleBar/TitleBar';
import TitleBarLoggedOut from '@/components/canvas/TitleBarLoggedOut/TitleBarLoggedOut';
import App from '@/components/composition/App/App';
import ErrorBoundary from '@/components/composition/ErrorBoundary/ErrorBoundary';
import GlobalAlertsProvider from '@/components/composition/GlobalAlerts/GlobalAlerts';
import IEBanner from '@/components/content/IEBanner/IEBanner';
import Logomark from '@/components/content/Logomark/Logomark';
import Nav from '@/components/controls/Nav/Nav';
import RecentlyViewed from '@/components/controls/RecentlyViewed/RecentlyViewed';
import TagManagerScript from '@/integrations/TagManagerScript';
import MarkerScript from '@/integrations/marker/MarkerScript';
import RouteAccess from '@/integrations/wrap-api/components/RouteAccess/RouteAccess';
import { useUser } from '@/lib/hooks';
import i18n from '@/lib/i18n';

import '../styles/main.scss';

function TheApp({
  serverRedirect,
  Component,
  pageProps,
  err,
  uaString,
}: {
  readonly uaString: string;
  readonly serverRedirect: (url: string) => void | null;
  readonly Component: any;
  readonly pageProps: any;
  readonly err: any;
}) {
  let ua;
  if (uaString) {
    ua = parse(uaString);
  } else if (
    typeof window !== 'undefined' &&
    typeof navigator !== 'undefined'
  ) {
    ua = parse(window.navigator.userAgent);
  }

  const { locale, pathname, basePath } = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  const [user] = useUser();

  return (
    <App>
      <RecentlyViewed.Log />
      <GlobalAlertsProvider>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, viewport-fit=cover"
          />

          <title>{t('common.meta.title')}</title>
          <meta name="description" content={t('common.meta.description')} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://laportal.wrap.ngo/uk" />
          <meta property="og:title" content={t('common.meta.title')} />
          <meta
            property="og:description"
            content={t('common.meta.description')}
          />
          <meta
            property="og:image"
            content="https://laportal.wrap.ngo/uk/images/og/app-preview.png"
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://laportal.wrap.ngo/uk" />
          <meta property="twitter:title" content={t('common.meta.title')} />
          <meta
            property="twitter:description"
            content={t('common.meta.description')}
          />
          <meta
            property="twitter:image"
            content="https://laportal.wrap.ngo/uk/images/og/app-preview.png"
          />

          <link rel="manifest" href={`${basePath}/site.webmanifest`} />
          <meta name="theme-color" content="#0b301a" />
          <meta name="application-name" content={t('common.meta.name')} />
          <link rel="shortcut icon" href={`${basePath}/favicon.ico`} />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${basePath}/images/app-icons/favicon-16x16.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${basePath}/images/app-icons/favicon-32x32.png`}
          />
          <meta
            name="apple-mobile-web-app-title"
            content={t('common.meta.name')}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${basePath}/images/app-icons/apple-touch-icon.png`}
          />
          <link
            rel="mask-icon"
            href={`${basePath}/images/app-icons/safari-pinned-tab.svg`}
            color="#0b301a"
          />
          <meta name="msapplication-TileColor" content="#0b301a" />
          <meta
            name="msapplication-config"
            content={`${basePath}/browserconfig.xml`}
          />
        </Head>
        <I18nextProvider i18n={i18n}>
          <RouteAccess serverRedirect={serverRedirect}>
            <App.Header>
              {user.id ? (
                <TitleBar>
                  <Nav />
                </TitleBar>
              ) : (
                <TitleBarLoggedOut
                  skin={pathname === '/' ? 'white' : 'transparent'}
                >
                  <Grid justify="space-between" align="center">
                    <Grid.Item>
                      <Logomark />
                    </Grid.Item>
                    <Grid.Item>
                      <a
                        href="https://wrap.ngo"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>wrap.ngo</strong> <Icon icon="arrow-right" />
                      </a>
                    </Grid.Item>
                  </Grid>
                </TitleBarLoggedOut>
              )}
            </App.Header>
            <App.Main>
              <ErrorBoundary key={pathname} user={user}>
                {ua?.isIE ? (
                  <IEBanner />
                ) : (
                  <Component {...pageProps} err={err} />
                )}
              </ErrorBoundary>
            </App.Main>
            <App.Footer>
              <Footer />
            </App.Footer>
          </RouteAccess>
        </I18nextProvider>
      </GlobalAlertsProvider>
      <MarkerScript />
      <TagManagerScript />
    </App>
  );
}

TheApp.getInitialProps = async (ctx: NextUrqlAppContext) => {
  const appProps = await NextApp.getInitialProps(ctx);

  const serverRedirect = ctx.ctx?.res?.writeHead
    ? (url) => {
        ctx.ctx.res.writeHead(302, {
          Location: url,
        });
        ctx.ctx.res.end();
      }
    : null;

  return {
    serverRedirect,
    uaString: ctx.ctx?.req?.headers['user-agent'],
    ...appProps,
  };
};

export default withUrqlClient(
  (ssrExchange, ctx) => ({
    url: process.env.NEXT_PUBLIC_API_URL || 'http://localhost:1337',
    fetchOptions: {
      credentials: 'include',
      mode: 'cors',
      headers: ctx?.req ? { cookie: ctx.req.headers.cookie } : undefined,
    },
    exchanges: [cacheExchange, ssrExchange, fetchExchange],
  }),
  { ssr: true },
)(TheApp as any);
