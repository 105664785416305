import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';

import { useComponentWillMount, useQuery } from '@/lib/hooks';
import { CURRENT_USER } from '@/lib/queries';
import { User } from '@/types/user.type';

import hasRouteAccess from './hasRouteAccess';

interface RouteAccessProps extends Readonly<PropsWithChildren> {
  /**
   * `router.push` is client-side only, so if this is a server side render we need an alternative
   * way to redirect. A server compatible redirect should be passed down from the `getInitialProps`
   * of `app.tsx`
   * @param url the URL to redirect to
   */
  readonly serverRedirect: (url: string) => void | null;
}

/**
 * This component wraps around the whole app to check the user has permissions
 * to access to the current route.
 */
export default function RouteAccess({
  serverRedirect,
  children,
}: RouteAccessProps) {
  const router = useRouter();
  const [userResponse, refetchUser] = useQuery<{ currentUser: User }>({
    query: CURRENT_USER,
  });
  const user = userResponse?.data?.currentUser;

  function handleRouteChange() {
    refetchUser({ requestPolicy: 'cache-and-network' });
  }

  function goToUrl(url: string) {
    const basePath = router.basePath;

    if (serverRedirect) {
      serverRedirect(`${basePath}${url}`);
    } else {
      router.push(url);
    }
  }

  function checkForRouteAccess() {
    // skip access checks whilst fetching or if there's no way to redirect
    if (
      userResponse.fetching ||
      (!serverRedirect && typeof window === 'undefined')
    ) {
      return;
    }

    const hasAccess = hasRouteAccess(user, router);

    if (!hasAccess && !user?.id) {
      goToUrl('/auth/login');
    } else if (!hasAccess && user?.id) {
      goToUrl('/dashboard?access_denied=true');
    }
  }

  // Run the checks once before load
  useComponentWillMount(() => checkForRouteAccess());

  // Re-run the checks if the user object changes
  useEffect(() => checkForRouteAccess(), [user]);

  // Refetch the user each time the route changes
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // If we're server-side in a static context, don't render anything
  // This prevents any protected content from being included in the static HTML
  if (!serverRedirect && typeof window === 'undefined') {
    return null;
  }

  return <>{children}</>;
}
