import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from '@/public/locales/en/common.json';
import eteepCommon from '@/public/locales/en/eteepCommon.json';
import eteepEconomic from '@/public/locales/en/eteepEconomic.json';
import eteepEnvironmental from '@/public/locales/en/eteepEnvironmental.json';
import eteepHome from '@/public/locales/en/eteepHome.json';
import eteepQualification from '@/public/locales/en/eteepQualification.json';
import eteepReport from '@/public/locales/en/eteepReport.json';
import eteepReportHome from '@/public/locales/en/eteepReportHome.json';
import eteepService from '@/public/locales/en/eteepService.json';
import eteepTechnical from '@/public/locales/en/eteepTechnical.json';

const resources = {
  en: {
    common,
    eteepCommon,
    eteepHome,
    eteepReportHome,
    eteepReport,
    eteepQualification,
    eteepService,
    eteepEconomic,
    eteepEnvironmental,
    eteepTechnical,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
